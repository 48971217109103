import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/SEO/Seo"
import "../../styles/servicios.scss"
import "../../styles/kitdigital.scss"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

export default function Servicios(props) {
  return (
    <Layout>
      <Seo title="Servicios" pathname={props.location.pathname} />
      <div className="cuerpo-servicios">
        <section className="container primera-seccion cabecera-servicios">
          <div className="caja-titulo">
            <h1>Servicios</h1>
          </div>
          <div className="caja-subtitulo">
            <p className="destacado">
              En bullhost.agency ofrecemos un catálogo completo de servicios
              enfocados a potenciar la imagen de tu empresa y hacer crecer tu
              negocio. Desde branding hasta tu propia web, pasando por
              estrategias de marketing que aumenten tu cartera de clientes.
            </p>
          </div>
        </section>
        <section
          className="caja-servicios container"
          id="tarjetas-index-servicios"
        >
          <article className="tarjeta-servicio">
            <Link to="/servicios/consultoria-y-estrategia">
              <div
                className="foto-servicio"
                style={{ backgroundImage: "url(/img/consultoria-opt.jpg)" }}
              ></div>
              <h2>Consultoría y estrategia</h2>
              <p className="subtitulo">
                Nadie se lanza al mar sin saber hacia dónde nadar o a dónde
                quiere llegar.
              </p>
            </Link>
          </article>
          <article className="tarjeta-servicio">
            <Link to="/servicios/marketing-y-content">
              <div
                className="foto-servicio"
                style={{ backgroundImage: "url(/img/faro.webp)" }}
              ></div>
              <h2>Marketing & Content</h2>
              <p className="subtitulo">
                Qué contar, cómo contarlo y dónde hacerlo para que tus acciones
                no acaben a la deriva en el océano digital.
              </p>
            </Link>
          </article>
          <article className="tarjeta-servicio">
            <Link to="/servicios/web-aplicaciones-y-proyectos-digitales">
              <div
                className="foto-servicio"
                style={{ backgroundImage: "url(/img/brujula.webp)" }}
              ></div>
              <h2>Web, Aplicaciones y Proyectos digitales</h2>
              <p className="subtitulo">
                Una página web ya no es algo que se tiene por tener, es una
                auténtica lancha fueraborda digital.
              </p>
            </Link>
          </article>
        </section>
        <section id="bloque-kit-digital" className="container">
          <div className="grid-kit-digital">
            <div className="col-i col-kit">
              <h2>Y ahora todo es más fácil con Kit Digital</h2>
              <p>
                Subvenciones a fondo perdido para la digitalización de tu
                empresa: para que puedas avanzar con el menor esfuerzo. Bullhost
                es Agente Digitalizador del programa Kit Digital y estamos
                autorizados a gestionar para tí estas ayudas.
              </p>
              <a
                href="https://bullhost.security/kit-digital/"
                target="_blank"
                rel="noreferrer noopener"
              >
                Más información
              </a>
            </div>
            <div className="col-d col-kit">
              <StaticImage
                src="../../../static/img/kit-digital-tablet-800.webp"
                objectFit="contain"
                alt="Bullhost y Kit Digital"
              />
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}
